<template>
  <v-container
    style="max-width: 1600px"
    :class="hasCallCenter ? 'call-center-margin' : ''"
  >
    <v-layout v-if="isModeAdd && isOnCall" row wrap>
      <v-flex shrink mb-0 mr-4>
        <h1 class="page-header">Current Call: {{ currentCallDurationText }}</h1>
      </v-flex>
      <v-flex shrink mb-3>
        <h1 v-if="call.brandPhone" class="page-header">
          Called: {{ call.brandCallerId }}
          {{ formatPhoneNumber(call.brandPhone) }}
        </h1>
      </v-flex>
    </v-layout>

    <v-form ref="quote-lead-form">
      <v-layout class="sheet" row wrap>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex shrink mb-0 mr-4>
              <h1 class="page-header mb-4">
                {{ modeTitle }} Lead
                <span v-if="lead.leadId">#{{ lead.leadId }}</span>
                <span v-if="lead.createdOn" class="timestamp">
                  <br />
                  <span class="label">Created:</span>
                  {{ createdOnDisplayText }}
                </span>
                <span v-if="lead.updatedOn" class="timestamp">
                  -
                  <span class="label">Last Updated:</span>
                  {{ updatedOnDisplayText }}
                </span>
              </h1>
            </v-flex>
            <v-spacer />
            <v-flex shrink>
              <v-btn v-if="showEditButton" color="primary" @click="editLead">
                Edit Lead
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md8 order-xs2 order-md1>
          <v-layout row wrap>
            <v-flex xs12 class="mb-4">Customer Information</v-flex>
            <v-flex xs6 md8 class="pr-3">
              <CRInput
                id="quote-lead-form-first-name"
                v-model="lead.firstName"
                :label="'First Name'"
                :rules="rules.firstName"
                :disabled="disabled"
                validate-on-blur
                placeholder="e.g. John"
              />
            </v-flex>
            <v-flex xs6 md8 :class="$cr.breakpoint.smAndDown ? '' : 'pr-3'">
              <CRInput
                id="quote-lead-form-last-name"
                v-model="lead.lastName"
                :label="'Last Name'"
                :rules="rules.lastName"
                :disabled="disabled"
                validate-on-blur
                placeholder="e.g. Doe"
              />
            </v-flex>
            <v-flex xs6 md8 class="pr-3">
              <CRInput
                id="quote-lead-form-phone"
                v-model="lead.phone"
                :mask="'phone'"
                :label="'Called In Phone'"
                :rules="rules.phone"
                :disabled="disabled"
                validate-on-blur
                placeholder="e.g. (555) 555-5555"
              />
            </v-flex>
            <v-flex xs6 md8 :class="$cr.breakpoint.smAndDown ? '' : 'pr-3'">
              <CRInput
                id="quote-lead-form-organization"
                v-model="lead.organization"
                :label="'Organization'"
                :disabled="disabled"
                placeholder="e.g. Acme Tools"
              />
            </v-flex>
            <v-flex v-if="!isModeView" xs12>
              <span
                id="quote-lead-form-clear-customer-info"
                :class="
                  enableClearButton
                    ? 'clear-customer-input-text_enabled'
                    : 'clear-customer-input-text_disabled'
                "
                @click="enableClearButton ? clearCustomerInfo() : ''"
              >
                Clear Customer Info
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="isModeAdd && isOnCall" xs12 md4 order-xs1 order-md2>
          <v-layout row>
            <v-flex xs12 class="mb-4">Caller Information</v-flex>
          </v-layout>
          <v-layout row wrap class="caller-id-box mb-4">
            <v-flex shrink class="mt-2 mb-2">
              <span class="caller-id-name">
                {{ callerId.firstName }} {{ callerId.lastName }}
              </span>
              <br />
              {{ formatPhoneNumber(callerId.phone) }}
              <br />
              {{ callerId.organization }}
            </v-flex>
            <v-spacer />
            <v-flex shrink align-self-center class="mt-2 mb-2">
              <v-btn
                color="primary"
                class="ma-0"
                @click="fillCustomerInfoFromCallerId"
              >
                Auto-Fill
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <!-- <v-flex row class="sheet pa-0"> -->
      <v-layout
        v-if="isModeAdd && isOnCall"
        class="sheet"
        row
        wrap
        align-center
      >
        <v-flex xs12 mb-3>Customer Call History</v-flex>
        <v-flex v-if="callHistory" xs12 md10>
          <v-layout row wrap>
            <v-flex shrink class="call-history-column">
              Date
              <br />
              <h3>{{ formatCallTime(callHistory.createdOn) }}</h3>
            </v-flex>
            <v-flex shrink class="call-history-column">
              Lead Source
              <br />
              <h3>{{ callHistory.leadSource.label }}</h3>
            </v-flex>
            <v-flex shrink class="call-history-column">
              Inbound Number
              <br />
              <h3>{{ formatPhoneNumber(callHistory.callTo) }}</h3>
            </v-flex>
            <v-flex shrink class="call-history-column">
              Answered By
              <br />
              <h3>{{ callHistory.answeredByName }}</h3>
            </v-flex>
            <v-flex shrink class="call-history-column">
              Call Action
              <br />
              <h3>{{ callHistory.callStatus.label }}</h3>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="callHistory" xs12 md2 text-md-right text-xs-center>
          <v-btn
            v-if="!showCallNotes"
            outline
            color="primary"
            @click="showCallNotes = true"
          >
            See Call Notes
          </v-btn>
          <v-btn v-else outline color="primary" @click="showCallNotes = false">
            Hide Call Notes
          </v-btn>
        </v-flex>
        <v-flex v-if="callHistory && showCallNotes" xs12>
          <v-flex shrink class="call-history-column">
            Call Notes
            <br />
            <span v-if="callHistory.callNotes.length">
              <p
                v-for="(notes, notesIndex) in callHistory.callNotes"
                :key="notesIndex"
              >
                {{ notes.note }}
              </p>
            </span>
            <p v-else>No notes for this call</p>
          </v-flex>
        </v-flex>
        <v-flex v-if="!callHistory" xs12>
          <v-layout row wrap>
            <v-flex shrink class="call-history-column">
              <h3>No Call History Detected</h3>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout class="sheet" row>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs6 md2 class="pr-3">
              <CRInput
                v-model="leadSourceName"
                label="Lead Source"
                :disabled="true"
              />
            </v-flex>
            <v-flex xs6 md2 class="pr-3">
              <CRInput
                v-model="lead.passengerCount"
                type="number"
                :rules="rules.passengerCount"
                :disabled="disabled"
                validate-on-blur
                label="Passenger Count"
                placeholder="Select"
              />
            </v-flex>
            <v-flex xs6 md2 class="pr-3">
              <CRInput
                v-model="lead.pickupDatetime"
                :floatinglabel="'Pickup Date'"
                type="date"
                :disabled="disabled"
                placeholder="Select Date"
                validate-on-blur
                :prepend-inner-event="true"
              />
            </v-flex>
            <v-flex xs6 md2 :class="$cr.breakpoint.smAndDown ? '' : 'pr-3'">
              <CRInput
                v-model="lead.dropoffDatetime"
                :floatinglabel="'Dropoff Date'"
                type="date"
                :disabled="disabled"
                placeholder="Select Date"
                validate-on-blur
                :prepend-inner-event="true"
              />
            </v-flex>
            <v-flex xs6 md2 class="pr-3">
              <CRSelect
                v-model="lead.bookingFor"
                type="select"
                :items="bookingForOptions"
                :rules="rules.bookingFor"
                :disabled="disabled"
                validate-on-blur
                label="Booking For"
                placeholder="Select"
              />
            </v-flex>
            <v-flex xs6 md2 class="pr-3">
              <CRSelect
                v-model="lead.bookingsPerYear"
                type="select"
                :items="bookingsPerYearOptions"
                :rules="rules.bookingsPerYear"
                :disabled="disabled"
                validate-on-blur
                label="Bookings Per Year"
                placeholder="Select"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex :class="$cr.breakpoint.smAndDown ? '' : 'pr-3'">
              <CRInput
                v-model="lead.notes"
                multi-line
                placeholder="Type in notes about the call"
                label="Call Notes"
                :disabled="disabled"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-spacer />
        <v-btn
          v-if="!disabled"
          color="primary"
          :disabled="disableSaveButton"
          style="padding: 0 36px"
          @click="saveLead"
        >
          Save Lead
        </v-btn>
        <v-spacer />
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import leadSources from '@/services/leadSources'
import { DateTime } from 'luxon'
import calls from '@/services/calls'
import leads from '@/services/leads'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { authComputed, callCenterComputed } from '@/state/helpers'
import { formatPhoneNumberToTenDigit } from '@/utils/phone'
import { filter } from '@/utils/filter'

export default {
  metaInfo() {
    return {
      title: 'Lead Form',
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        (from.name === 'leads.view' || from.name === 'leads.edit') &&
        to.name === 'leads.add'
      ) {
        vm.resetLeadData()
      }
    })
  },
  props: {
    mode: {
      type: String,
      default: 'view',
    },
  },
  data() {
    return {
      lead: {
        leadId: null,
        leadSourceTypeId: null,
        bookingsPerYear: null,
        bookingFor: null,
        passengerCount: null,
        pickupDatetime: null,
        dropoffDatetime: null,
        notes: null,
        firstName: null,
        lastName: null,
        phone: null,
        organization: null,
      },
      suggestedLeadSources: [],
      callHistory: null,
      leadSourceName: '',
      bookingsPerYearOptions: ['1', '2-3', '4-9', '10-14', '15-19', '20+'],
      bookingForOptions: ['Personal', 'Organization'],
      rules: {
        firstName: [
          isRequired(true, isNotEmpty, {
            req: 'First name is required',
            error: 'First name is required',
          }),
        ],
        lastName: [
          isRequired(true, isNotEmpty, {
            req: 'Last name is required',
            error: 'Last name is required',
          }),
        ],
        phone: [
          isRequired(true, isNotEmpty, {
            req: 'Phone number is required',
            error: 'Phone number is required',
          }),
        ],
        bookingsPerYear: [
          isRequired(true, isNotEmpty, {
            req: 'This question is required',
            error: 'This question is required',
          }),
        ],
        bookingFor: [
          isRequired(true, isNotEmpty, {
            req: 'This question is required',
            error: 'This question is required',
          }),
        ],
        passengerCount: [
          isRequired(true, isNotEmpty, {
            req: 'This question is required',
            error: 'This question is required',
          }),
        ],
      },
      callTimer: null,
      duration: 0,
      showCallNotes: false,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    modeTitle() {
      return this.mode.charAt(0).toUpperCase() + this.mode.slice(1)
    },
    disabled() {
      return this.isModeView || (!this.isModeAdd && this.isSDR)
    },
    createdOnDisplayText() {
      return this.formatDateTimeForDisplay(this.lead?.createdOn)
    },
    updatedOnDisplayText() {
      return this.formatDateTimeForDisplay(this.lead?.updatedOn)
    },
    showEditButton() {
      return !this.isSDR && this.isModeView
    },
    enableClearButton() {
      return (
        (this.lead.firstName ||
          this.lead.lastName ||
          this.lead.phone ||
          this.lead.organization) &&
        !this.disabled
      )
    },
    callerId() {
      let callerId = {
        firstName: '',
        lastName: '',
        phone: '',
        organization: '',
      }
      if (this.callerName) {
        let callerName =
          this.callerName[this.callerName.length - 1] === '.'
            ? this.callerName.slice(0, -1)
            : this.callerName
        callerName = callerName.split(' ')
        if (callerName[0]) {
          let firstName = callerName[0].toLowerCase()
          callerId.firstName =
            firstName.charAt(0).toUpperCase() + firstName.slice(1)
        }
        if (callerName[1]) {
          let lastName = callerName[1].toLowerCase()
          callerId.lastName =
            lastName.charAt(0).toUpperCase() + lastName.slice(1)
        }
      }
      if (this.callerPhone) {
        callerId.phone =
          this.callerPhone.length === 11
            ? this.callerPhone.substring(1)
            : this.callerPhone
      }
      return callerId
    },
    currentCallDurationText() {
      if (this.callStartTime) {
        const minutes = Math.floor(this.duration / 60)
        const seconds = this.duration % 60
        return `${minutes}m ${seconds}s`
      }
      return null
    },
    disableSaveButton() {
      return !(
        this.lead.bookingsPerYear &&
        this.lead.bookingFor &&
        this.lead.passengerCount &&
        this.lead.firstName &&
        this.lead.lastName &&
        this.lead.phone
      )
    },
    routeLeadId() {
      return this.$route.params?.id
    },
  },
  watch: {
    'lead.pickupDatetime'() {
      if (this.lead.pickupDatetime && this.lead.pickupDatetime.length !== 10) {
        this.lead.pickupDatetime = this.lead.pickupDatetime.substring(0, 10)
      }
    },
    'lead.dropoffDatetime'() {
      if (
        this.lead.dropoffDatetime &&
        this.lead.dropoffDatetime.length !== 10
      ) {
        this.lead.dropoffDatetime = this.lead.dropoffDatetime.substring(0, 10)
      }
    },
    async routeLeadId() {
      if (!this.routeLeadId) {
        return
      }
      const { data } = await leads.getLead(this.routeLeadId)
      this.lead = data.lead
    },
    async callSid() {
      if (this.isModeAdd) {
        this.getLeadSourceByBrandPhone(true)
      }
    },
  },
  async mounted() {
    this.createTimer()
    if (!this.isModeAdd) {
      await this.populateLeadForm()
    }

    await this.getLeadSource()
    if (this.isModeAdd && this.isOnCall && this.callerPhone) {
      await this.populateCallHistory()
    }
  },
  methods: {
    async resetLeadData() {
      this.callHistory = null
      this.lead = {
        leadId: null,
        leadSourceTypeId: null,
        bookingsPerYear: null,
        bookingFor: null,
        passengerCount: null,
        pickupDatetime: null,
        dropoffDatetime: null,
        notes: null,
        firstName: null,
        lastName: null,
        phone: null,
        organization: null,
      }

      this.createTimer()
      await this.getLeadSource()
      if (this.isModeAdd && this.isOnCall && this.callerPhone) {
        await this.populateCallHistory()
      }
    },
    async populateLeadForm() {
      const leadResponse = await leads.getLead(this.$route.params.id)
      this.lead = leadResponse.data.lead
    },
    createTimer() {
      if (this.callTimer) {
        clearInterval(this.callTimer)
      }
      if (this.isOnCall) {
        this.duration = Math.round(
          DateTime.local()
            .diff(DateTime.fromISO(this.callStartTime), ['seconds'])
            .toObject().seconds
        )
        this.callTimer = setInterval(() => {
          this.duration++
        }, 1000)
      }
    },
    formatPhoneNumber(phoneNumber) {
      var cleaned = ('' + phoneNumber).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return [match[2], '-', match[3], '-', match[4]].join('')
      }
      return null
    },
    formatCallTime(timestamp) {
      const dt = DateTime.fromISO(timestamp)
      return `${dt.toISODate()} ${dt.toLocaleString(
        DateTime.TIME_WITH_SECONDS
      )}`
    },
    async populateCallHistory() {
      const callerPhoneNumber =
        this.callerPhone[0] === '+' ? this.callerPhone : `+${this.callerPhone}`
      const lastCompletedCallResponse = await calls.getLastCompletedCallByPhoneNumber(
        callerPhoneNumber
      )
      let callHistory = lastCompletedCallResponse.data.call
      const leadSourceResponse = await leadSources.getLeadSourceFromPhone(
        callHistory.callTo
      )
      callHistory.leadSource = leadSourceResponse.data.leadSourceTypeDTO
      this.callHistory = callHistory
    },
    async getLeadSource() {
      if (this.isModeAdd) {
        this.getLeadSourceByBrandPhone()
      } else {
        this.leadSourceName = this.lead.leadSourceType.label
      }
    },
    async searchLeadSourceList(value) {
      if (typeof value !== 'string' || value.length === 0) {
        return
      }

      const filterObject = {
        column: {
          _t_id: 'lead_source_search_id',
          prop: 'displayName',
          filterType: 'contains',
          filterAsIs: true,
        },
        value,
      }
      const leadSourceFilter = filter()
      const parentFilter = leadSourceFilter.createParent('and')
      leadSourceFilter.add(parentFilter, filterObject)
      if (this.leadSourceDebounce) {
        clearTimeout(this.leadSourceDebounce)
      }
      this.leadSourceDebounce = setTimeout(async () => {
        const params = {
          filters: leadSourceFilter.asQueryParams(),
          pageSize: 5,
        }
        const matchedLeadSources = await leadSources.getLeadSources(params)
        const suggestedLeadSources = matchedLeadSources?.data?.resultList || []
        this.suggestedLeadSources = suggestedLeadSources.filter(
          (suggestedLeadSource) => suggestedLeadSource.internalName
        )
      }, 500)
    },
    fillCustomerInfoFromCallerId() {
      this.lead.firstName = this.callerId.firstName
      this.lead.lastName = this.callerId.lastName
      this.lead.phone = formatPhoneNumberToTenDigit(this.callerId.phone)
      this.lead.organization = this.callerId.organization
    },
    clearCustomerInfo() {
      this.lead.firstName = null
      this.lead.lastName = null
      this.lead.phone = null
      this.lead.organization = null
    },
    async saveLead() {
      if (this.$refs['quote-lead-form'].validate()) {
        if (this.isModeAdd) {
          try {
            const leadResponse = await leads.createLead(this.lead)
            if (this.callSid) {
              await calls.setLeadOnCall({
                callSid: this.callSid,
                leadId: leadResponse.data.lead.leadId,
              })
            }
            this.$router.push({
              name: 'leads.view',
              params: { id: leadResponse.data.lead.leadId },
            })
            this.$store.dispatch('app/showAlert', {
              color: 'success',
              message: 'Lead successfully created',
            })
          } catch (e) {
            this.$store.dispatch('app/showAlert', {
              color: 'error',
              message: 'Error creating lead',
            })
          }
        } else if (this.isModeEdit) {
          try {
            const leadResponse = await leads.updateLead(this.lead)
            this.$router.push({
              name: 'leads.view',
              params: { id: leadResponse.data.lead.leadId },
            })
            this.$store.dispatch('app/showAlert', {
              color: 'success',
              message: 'Lead successfully updated',
            })
          } catch (e) {
            this.$store.dispatch('app/showAlert', {
              color: 'error',
              message: 'Error updating lead',
            })
          }
        }
      }
    },
    formatDateTimeForDisplay(dateTime) {
      const date = DateTime.fromISO(dateTime).toLocaleString(
        DateTime.DATE_SHORT
      )
      const time = DateTime.fromISO(dateTime).toLocaleString(
        DateTime.TIME_SIMPLE
      )
      return `${date} ${time}`
    },
    editLead() {
      this.$router.push({
        name: 'leads.edit',
        params: { id: this.lead.leadId },
      })
    },
    async getLeadSourceByBrandPhone(overrideOnCall = false) {
      if ((this.isOnCall || overrideOnCall) && this.brandPhone) {
        try {
          const leadSourceResponse = await leadSources.getLeadSourceFromPhone(
            this.brandPhone
          )
          this.lead.leadSourceTypeId =
            leadSourceResponse.data.leadSourceTypeDTO.id
          this.leadSourceName = leadSourceResponse.data.leadSourceTypeDTO.label
        } catch (err) {
          this.getCharterUPLeadSource()
        }
      } else {
        this.getCharterUPLeadSource()
      }
    },
    async getCharterUPLeadSource() {
      await this.searchLeadSourceList('CharterUP')
      let timeout = setInterval(() => {
        if (this.suggestedLeadSources.length) {
          this.lead.leadSourceTypeId = this.suggestedLeadSources[0].leadSourceId
          this.leadSourceName = this.suggestedLeadSources[0].displayName
          clearInterval(timeout)
        }
      }, 50)
    },
  },
}
</script>

<style lang="scss" scoped>
.call-history-column {
  margin: 0 24px 16px 0;
}
.caller-id-box {
  max-width: 600px;
  padding: 16px 24px;
  border: 1px solid $border-gray;
  border-radius: 5px;
  .caller-id-name {
    font-weight: bold;
  }
}
.clear-customer-input-text {
  &_enabled {
    color: $primary;
    cursor: pointer;
  }
  &_disabled {
    color: $gray-medium-light;
    cursor: default;
  }
}
.timestamp {
  font-size: 12px;

  .label {
    font-weight: bold;
  }
}
</style>
